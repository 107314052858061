import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyFetchTwinQuery } from 'store/api/tools-v1';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { handleRTKQueryError } from 'store/errorState/errorState';
import ReactJson from 'react-json-view';
import { isReactSubmitEventWithSubmitter } from 'utils/typeUtils';
import { browserDownloadJsonFile } from 'utils/downloadUtils';
import { setRTKQueryErrorInfo } from 'utils/errorUtils';
import { FetchTwinForm, FormInput } from './FetchTwinForm';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { roles } from '../../constants';
import { StyledFetchTwinPresentation } from './styled';

export const FetchTwinPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramDeviceId = searchParams.get('deviceId') ?? undefined;
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  const [fetchTwin, { data, error: queryError, isFetching, isSuccess }] = useLazyFetchTwinQuery();

  const { role } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const onSubmit: SubmitHandler<FormInput> = (formData, event) => {
    // Check if the button with id "download" triggered the form submit
    if (isReactSubmitEventWithSubmitter(event) && event.nativeEvent.submitter.id === 'download') {
      // Check if the user wants to download the same file again
      if (data && formData.deviceId === deviceId) {
        browserDownloadJsonFile(data, `${formData.deviceId}.json`);
      } else {
        fetchTwin(formData.deviceId)
          .unwrap()
          .then((result) => browserDownloadJsonFile(result, `${formData.deviceId}.json`));
      }
    } else {
      fetchTwin(formData.deviceId);
    }
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, deviceId: formData.deviceId };
    setSearchParams(params, { replace: true });
    setDeviceId(formData.deviceId);
  };

  useEffect(() => {
    if (queryError) {
      dispatch(handleRTKQueryError(queryError));
    }
  }, [queryError]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      deviceId: deviceId ?? '',
    },
  });

  return (
    <>
      <ToolSideBar>
        <FetchTwinForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
          placeholder="123456789-123456789"
        />
      </ToolSideBar>
      <StyledPageWrapper>
        <StyledFetchTwinPresentation>
          <h1>Fetch Twin</h1>
          {isFetching && !queryError && <h4>Loading twin for {deviceId}...</h4>}
          {queryError && (
            <div>
              <h2>
                {setRTKQueryErrorInfo(queryError)[0]} - {setRTKQueryErrorInfo(queryError)[1]}
              </h2>
            </div>
          )}
          {!(isFetching && queryError) && isSuccess && data && (
            <>
              <h3>Fetched twin successfully!</h3>
              <ReactJson src={data} displayObjectSize={false} collapsed={1} name={false} />
            </>
          )}
          <hr />
          <h4>Instructions</h4>
          <p>
            To use this tool, choose the environment in the page top panel to which the mower is
            currently pointing.
          </p>
          <p>
            In the form, the device ID for a G3 mower is the nine-digit serial number of the mower,
            followed by a dash,
          </p>
          <p>
            followed by the nine-digit comboard ID. For G4 mowers, just input the nine-digit serial
            number of the mower.
          </p>
        </StyledFetchTwinPresentation>
      </StyledPageWrapper>
    </>
  );
};
